<template>
  <div class="">
    <div class="tihsi font14">
      立即<span @click="$router.push('/downapp')">下载APP</span
      >,随时随地找工作，实时查看面试进度、接收面试通知和Offer管理 。
    </div>
    <!-- 无记录 -->
    <div class="nodata" v-if="recordList.length == 0">
      <img src="../../assets/images/common/search-no.png" />
      <div class="font18" v-if="!loading">当前投递记录空</div>
      <div class="font18" v-if="loading">加载中</div>
    </div>
    <!-- 有记录 -->
    <div class="">
      <el-card class="positionlist">
        <div
          class="box-card p-list"
          v-for="(itemli, indexes) in recordList"
          :key="indexes"
        >
          <div class="p-listbox">
            <div class="pl-left font14">
              <div class="l-one">
                <div class="font16">
                  {{ itemli.jobName }}
                  <span class="biaoqian1" v-if="itemli.jobType == 1">Free</span>
                  <!-- <span class="biaoqian4">职位已关闭</span> -->
                </div>
              </div>
              <div class="l-two">
                {{ itemli.city }} |
                {{
                  itemli.educationType.length
                    ? itemli.educationType[0].itemText
                    : "/"
                }}
                | {{ itemli.expLow }} - {{ itemli.expHigh }}年 |
                {{ (itemli.salaryLow / 1000) | numFilter }}
                  K -
                {{ (itemli.salaryHigh / 1000) | numFilter}}
                  K
              </div>
              <div class="l-three" v-if="itemli.startDate">
                项目周期：{{ itemli.startDate ? itemli.startDate : "/" }} -
                {{ itemli.endDate ? itemli.endDate : "/" }}
              </div>
            </div>
            <div class="pl-right font14">
              <div class="synum">
                剩余招聘:<span>{{ itemli.jobFree }}</span
                >人
              </div>
              <div class="time">{{ itemli.shortName }}</div>
              <div class="czbtn">
                <img
                  :src="
                    itemli.companyAvatar
                      ? 'https://freemen.work/user/file/view/' +
                        itemli.companyAvatar
                      : defaultImg
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="nextpage" @click="getMoreList" v-if="moreBtn">
          点击加载更多
        </div>
      </el-card>

      <!-- <div class="paginate" style="position: relative;">
				<el-pagination
					@current-change="handleCurrentChange"
					:current-page="pageInfo.pageNum"
					:page-size="pageInfo.pageSize"
					layout="total, prev, pager, next, jumper"
					:total="pageInfo.count"
				></el-pagination>
			</div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      recordList: [],
      moreBtn: false,
      loading: false,
      defaultImg: require("../../assets/logo.png"),
    };
  },
  created() {
    this.getPostRecord();
  },
  mounted() {},
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    getPostRecord() {
      this.loading = true;

      //获取记录列表
      this.$api.getPostRecord("get", this.pageInfo).then((res) => {
        this.loading = false;

        this.recordList = this.recordList.concat(res.data);
        // console.log(this.recordList);
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
      });
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getPostRecord();
    },
    handleCurrentChange() {
      // val
      // this.fetchData(val);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}
/deep/.el-card__body {
  padding: 0px 0;
}
.tihsi {
  background-color: #444c5f;
  color: #abb0ba;
  padding: 0px 30px;
  line-height: 44px;
  span {
    color: #00bcff;
  }
}
.paginate {
  margin: 130px 0 100px 0 !important;
  .el-pagination {
    padding: 0 !important;
  }
}
.positionlist {
  margin-top: 20px;
  .nextpage {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    cursor: pointer;
    margin: 15px 0;
  }
  .p-list:hover {
    // transform: scale(1.01);
    background-color: rgba(0, 0, 0, 0.03);
  }
  .p-list {
    // margin-bottom: 20px;
    border-bottom: 1px solid #f3f3f3;
    padding: 20px;
    .p-listbox {
      display: flex;
      align-items: center;
      // padding-bottom: 20px;
      .pl-left {
        display: flex;
        flex: 1;
        // align-items: center;
        flex-direction: column;
        line-height: 2.2;
        .l-one {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          div {
            color: #111111;
            font-weight: 600;
            // span {
            // 	color: #ff8400;

            // }
            .biaoqian1 {
              background-color: #e9f9ff;
              color: #00bcff;
              padding: 1px 10px;
              margin: 0 10px;
              font-size: 14px;
              font-weight: 500;
            }
            .biaoqian4 {
              background-color: #bbbbbb;
              color: #ffffff;
              padding: 1px 10px;
              margin-left: 10px;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
        .l-two {
          color: #666666;
        }
        .l-three {
          color: #666666;
        }
      }
      .pl-right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #666666;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
        }
        .time {
          color: #111111;
        }
        .synum {
          span {
            color: #111111;
          }
        }
        .czbtn {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
.nodata {
  background-color: #ffffff;
  // box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0 96px 0;
  margin-top: 20px;
  div {
    color: #111111;
    margin-top: 20px;
  }

  img {
    width: 260px;
    height: 220px;
  }
}
</style>
